/*------------------------------------
  Leaflet
------------------------------------*/

.leaflet {
  min-height: $leaflet-min-height;
  height: 100%;
  z-index: $leaflet-z-index;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
}

.leaflet-bar,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: $leaflet-popup-wrapper-box-shadow;
}

.leaflet-bar,
.leaflet-popup-content-wrapper {
  @include border-radius($leaflet-popup-wrapper-border-radius);
}

.leaflet-popup {
  margin-bottom: $leaflet-popup-margin-y;
}