/*------------------------------------
  Video Bg
------------------------------------*/

.video-bg {
  position: relative;
  min-height: $video-bg-min-height;
}

.video-bg-content {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-bg-replacer-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

@include media-breakpoint-up(md) {
  .video-bg-replacer-img {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .video-bg-content {
    display: none;
  }
}

.hs-video-bg-video iframe,
.hs-video-bg-video video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
