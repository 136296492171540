/*------------------------------------
  Shapes
------------------------------------*/

.shape-container {
  position: relative;
}

.shape[class*="text-"] > svg [fill]:not([fill="none"]) {
  fill: currentColor !important;
}

.shape {
  position: absolute;
  z-index: -1;
}

.shape-top {
  top: 0;
  left: 0;
  right: 0;

  > svg {
    width: 100%;
    height: auto;
    margin-bottom: -1px;
    transform-origin: bottom center;
  }
}

.shape-bottom {
  bottom: 0;
  left: 0;
  right: 0;

  > svg {
    width: 100%;
    height: auto;
    margin-bottom: -1px;
    transform-origin: top center;
  }
}