/*------------------------------------
  Default Styles
------------------------------------*/

a {
  text-decoration: none;
}

:focus,
a:focus,
button:focus {
  outline-color: rgba($primary, .5);
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: rfs-fluid-value(4rem);
  }

  h2 {
    font-size: rfs-fluid-value(3rem);
  }
}

figure {
  margin-bottom: 0;
}

p, ul {
  color: $paragraph-color;
}

dt {
  color: $dt-color;
}

dd {
  margin-bottom: $dd-margin-y;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Highlight Color */
::-moz-selection {
  color: $primary;
  background-color: rgba($primary, .1);
}

::selection {
  color: $primary;
  background-color: rgba($primary, .1);
}

.bg-primary ::-moz-selection {
  color: $white;
  background-color: rgba($white, .1);
}

.bg-primary ::selection {
  color: $white;
  background-color: rgba($white, .1);
}