/*------------------------------------
  List Checked
------------------------------------*/

.list-checked {
  padding-left: 0;
  list-style: none;
}

.list-checked-item,
.list-unchecked-item {
  position: relative;
  color: $list-checked-item-color;
  padding-left: $list-checked-item-padding-x;

  &:not(:last-child) {
    margin-bottom: $list-checked-item-margin-y;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: $list-checked-pseudo-width;
    height: $list-checked-pseudo-height;
    background-image: escape-svg($list-checked-pseudo);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: $list-checked-pseudo-width $list-checked-pseudo-height;
    content: '';
    margin-top: $list-checked-pseudo-margin-y;
  }

  &[hover]:hover {
    color: $list-checked-item-hover-color;
  }
}

[class*="list-checked-bg-"],
[class*="list-checked-soft-bg-"] {
  .list-checked-item::before,
  .list-unchecked-item::before {
    margin-top: $list-checked-bg-pseudo-margin-y;
  }
}

// Styles
@each $color, $value in $theme-colors {
  .list-checked-#{$color} {
    .list-checked-item::before {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='#{$value}'/></svg>"));
    }

    .list-unchecked-item::before {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.20895 4.21806C4.34279 4.08416 4.52428 4.00893 4.71352 4.00893C4.90276 4.00893 5.08425 4.08416 5.21808 4.21806L8.99554 7.99878L12.773 4.21806C12.8388 4.14984 12.9176 4.09543 13.0046 4.05799C13.0917 4.02056 13.1854 4.00085 13.2801 4.00003C13.3749 3.9992 13.4689 4.01728 13.5566 4.05319C13.6443 4.08911 13.724 4.14214 13.791 4.20921C13.858 4.27628 13.911 4.35603 13.9469 4.44381C13.9827 4.53159 14.0008 4.62565 14 4.72049C13.9991 4.81533 13.9795 4.90906 13.9421 4.99621C13.9047 5.08335 13.8503 5.16217 13.7821 5.22806L10.0047 9.00878L13.7821 12.7895C13.9121 12.9242 13.9841 13.1046 13.9824 13.2919C13.9808 13.4792 13.9058 13.6584 13.7734 13.7908C13.6411 13.9232 13.4621 13.9983 13.275 14C13.0879 14.0016 12.9076 13.9296 12.773 13.7995L8.99554 10.0188L5.21808 13.7995C5.08348 13.9296 4.90321 14.0016 4.71609 14C4.52896 13.9983 4.34997 13.9232 4.21765 13.7908C4.08533 13.6584 4.01027 13.4792 4.00864 13.2919C4.00702 13.1046 4.07895 12.9242 4.20895 12.7895L7.98641 9.00878L4.20895 5.22806C4.07516 5.09411 4 4.91247 4 4.72306C4 4.53366 4.07516 4.35201 4.20895 4.21806V4.21806Z' fill='#{$text-muted}'/></svg>"));
    }
  }

  .list-checked-bg-#{$color} {
    .list-checked-item::before {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18' height='18' rx='9' fill='#{$value}'/><path d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='white'/></svg>"));
    }

    .list-unchecked-item::before {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9Z' fill='#{$text-muted}'/%3E%3Cpath d='M5.64639 5.64654C5.74016 5.55281 5.86731 5.50015 5.99989 5.50015C6.13248 5.50015 6.25963 5.55281 6.35339 5.64654L8.99989 8.29304L11.6464 5.64654C11.6925 5.59879 11.7477 5.56069 11.8087 5.53449C11.8697 5.50829 11.9353 5.49449 12.0017 5.49392C12.0681 5.49334 12.1339 5.50599 12.1954 5.53113C12.2568 5.55627 12.3126 5.5934 12.3596 5.64034C12.4065 5.68729 12.4437 5.74312 12.4688 5.80456C12.4939 5.86601 12.5066 5.93185 12.506 5.99824C12.5054 6.06463 12.4916 6.13024 12.4654 6.19124C12.4392 6.25224 12.4011 6.30742 12.3534 6.35354L9.70689 9.00004L12.3534 11.6465C12.4445 11.7408 12.4949 11.8671 12.4937 11.9982C12.4926 12.1293 12.44 12.2547 12.3473 12.3474C12.2546 12.4402 12.1292 12.4927 11.9981 12.4939C11.867 12.495 11.7407 12.4446 11.6464 12.3535L8.99989 9.70704L6.35339 12.3535C6.25909 12.4446 6.13279 12.495 6.00169 12.4939C5.87059 12.4927 5.74519 12.4402 5.65248 12.3474C5.55978 12.2547 5.5072 12.1293 5.50606 11.9982C5.50492 11.8671 5.55531 11.7408 5.64639 11.6465L8.29289 9.00004L5.64639 6.35354C5.55266 6.25978 5.5 6.13262 5.5 6.00004C5.5 5.86746 5.55266 5.7403 5.64639 5.64654V5.64654Z' fill='#{$white}'/></svg>"));
    }
  }

  .list-checked-soft-bg-#{$color} {
    .list-checked-item::before {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18' height='18' rx='9' fill='#{$value}' fill-opacity='0.1'/><path d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='#{$value}'/></svg>"));
    }

    .list-unchecked-item::before {
      background-image: escape-svg(url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9Z' fill='#{$text-muted}' fill-opacity='0.1'/%3E%3Cpath d='M5.64639 5.64654C5.74016 5.55281 5.86731 5.50015 5.99989 5.50015C6.13248 5.50015 6.25963 5.55281 6.35339 5.64654L8.99989 8.29304L11.6464 5.64654C11.6925 5.59879 11.7477 5.56069 11.8087 5.53449C11.8697 5.50829 11.9353 5.49449 12.0017 5.49392C12.0681 5.49334 12.1339 5.50599 12.1954 5.53113C12.2568 5.55627 12.3126 5.5934 12.3596 5.64034C12.4065 5.68729 12.4437 5.74312 12.4688 5.80456C12.4939 5.86601 12.5066 5.93185 12.506 5.99824C12.5054 6.06463 12.4916 6.13024 12.4654 6.19124C12.4392 6.25224 12.4011 6.30742 12.3534 6.35354L9.70689 9.00004L12.3534 11.6465C12.4445 11.7408 12.4949 11.8671 12.4937 11.9982C12.4926 12.1293 12.44 12.2547 12.3473 12.3474C12.2546 12.4402 12.1292 12.4927 11.9981 12.4939C11.867 12.495 11.7407 12.4446 11.6464 12.3535L8.99989 9.70704L6.35339 12.3535C6.25909 12.4446 6.13279 12.495 6.00169 12.4939C5.87059 12.4927 5.74519 12.4402 5.65248 12.3474C5.55978 12.2547 5.5072 12.1293 5.50606 11.9982C5.50492 11.8671 5.55531 11.7408 5.64639 11.6465L8.29289 9.00004L5.64639 6.35354C5.55266 6.25978 5.5 6.13262 5.5 6.00004C5.5 5.86746 5.55266 5.7403 5.64639 5.64654V5.64654Z' fill='#{$text-muted}'/></svg>"));
    }
  }
}

// Sizes
.list-checked-sm {
  .list-checked-item,
  .list-unchecked-item {
    padding-left: $list-checked-sm-item-padding-x;

    &::before {
      width: $list-checked-sm-pseudo-width;
      height: $list-checked-sm-pseudo-height;
      background-size: $list-checked-sm-pseudo-width $list-checked-sm-pseudo-height;
      margin-top: $list-checked-sm-pseudo-margin-y;
    }
  }

  &[class*="list-checked-bg-"],
  &[class*="list-checked-soft-bg-"] {
    .list-checked-item::before,
    .list-unchecked-item::before {
      margin-top: $list-checked-sm-bg-pseudo-margin-y;
    }
  }
}

.list-checked-lg {
  .list-checked-item,
  .list-unchecked-item {
    padding-left: $list-checked-lg-item-padding-x;

    &:not(:last-child) {
      margin-bottom: $list-checked-lg-item-margin-y;
    }

    &::before {
      width: $list-checked-lg-pseudo-width;
      height: $list-checked-lg-pseudo-height;
      background-size: $list-checked-lg-pseudo-width $list-checked-lg-pseudo-height;
      margin-top: $list-checked-lg-pseudo-margin-y;
    }
  }

  &[class*="list-checked-bg-"],
  &[class*="list-checked-soft-bg-"] {
    .list-checked-item::before,
    .list-unchecked-item::before {
      margin-top: -$list-checked-lg-bg-pseudo-margin-y;
    }
  }
}