/*------------------------------------
  List Equal Height
------------------------------------*/

.list-equal-height {
  position: relative;
  display: grid;
  overflow: hidden;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-equal-height-2-cols {
  grid-template-columns: 50% 50%;
}

.list-equal-height-3-cols {
  grid-template-columns: 33% 33% 33%;
}

.list-equal-height-4-cols {
  grid-template-columns: 25% 25% 25% 25%;
}

.list-equal-height-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $list-equal-height-item-padding-y $list-equal-height-item-padding-x;
}

.list-equal-height-item::before,
.list-equal-height-item::after {
  position: absolute;
  left: 0;
  bottom: 0;
  border: 0 solid $list-equal-height-border-color;
  content: '';
}

.list-equal-height-item::before {
  top: 0;
  border-right-width: $list-equal-height-border-width;
  margin-left: -$list-equal-height-border-width;
}

.list-equal-height-item::after {
  right: 0;
  border-bottom-width: $list-equal-height-border-width;
  margin-bottom: -$list-equal-height-border-width;
}

// Large Devices
@include media-breakpoint-down(lg) {
  .list-equal-height-4-cols {
    grid-template-columns: 33% 33% 33%;
  }
}

// Medium Devices
@include media-breakpoint-down(md) {
  .list-equal-height-3-cols,
  .list-equal-height-4-cols {
    grid-template-columns: 50% 50%;
  }
}

// Small Devices
@include media-breakpoint-down(sm) {
  .list-equal-height {
    grid-template-columns: 100%;
  }

  .list-equal-height-item {
    padding: calc($list-equal-height-item-padding-y / 2) 0;
    margin-bottom: calc($list-equal-height-item-padding-y / 2);

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      
      &::after {
        display: none;
      }
    }
  }
}